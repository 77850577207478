import React, { PropsWithChildren } from "react";
import { useContext } from "react";
interface PartnerContextProps {
  partnerSlug: string | undefined;
}
const PartnerContext = React.createContext<PartnerContextProps>({
  partnerSlug: undefined
});
export const usePartnerSlug = (partnerName: string | undefined) => {
  return partnerName || useContext(PartnerContext).partnerSlug;
};
export const PartnerProvider: React.FC<PropsWithChildren<PartnerContextProps>> = ({
  children,
  partnerSlug
}) => {
  return <PartnerContext.Provider value={{
    partnerSlug
  }} data-sentry-element="unknown" data-sentry-component="PartnerProvider" data-sentry-source-file="PartnerContext.tsx">
      {children}
    </PartnerContext.Provider>;
};